<template>
  <div class="bg-white min-h-full flex flex-col lg:relative">
    <div class="flex-grow flex flex-col">
      <main class="flex-grow flex flex-col bg-white">
        <div class="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
          <div class="flex-shrink-0 pt-10 sm:pt-16">
            <a href="/" class="inline-flex">
              <span class="sr-only">Flaze</span>
              <img class="h-12 w-auto" src="@/assets/logo.svg" alt="" />
            </a>
          </div>
          <div class="flex-shrink-0 my-auto py-16 sm:py-32">
            <p class="text-sm font-semibold text-purple-600 uppercase tracking-wide">It's time to move on</p>
            <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Flaze has shut down.</h1>
            <p class="mt-6 text-base text-gray-500">
              Flaze has suspended operations.
            </p>
            <p class="mt-2 text-base text-gray-500">
              All subscriptions have been cancelled, and payments since<br>October 14th have been refunded.
            </p>
            <p class="mt-2 text-base text-gray-500">
              It may take up to 7 days for refunds to arrive in your account.
            </p>
            <p class="mt-2 text-base text-gray-500">
              From the whole team, thank you for supporting the platform.
            </p>
            <div class="mt-6">
              <a href="https://patreon.com" class="text-base font-medium text-purple-600 hover:text-purple-500">Check out Patreon<span aria-hidden="true"> &rarr;</span></a>
            </div>
          </div>
        </div>
      </main>
      <footer class="flex-shrink-0 bg-gray-50">
        <div class="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
          <nav class="flex space-x-4">
            <a href="mailto:support@flaze.com" class="text-sm font-medium text-gray-500 hover:text-gray-600">Contact Support</a>
          </nav>
        </div>
      </footer>
    </div>
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80" alt="" />
    </div>
  </div>
</template>
